import React, { useState } from "react";
import { languages } from "../../assets/languages";
import { store } from "../../store";
import { useSelector } from "react-redux";
import { changeLanguage } from "../../reducers/app-reducer";

function LanguagePicker() {
  const language = useSelector((s) => s.app.language);
  const [active, setActive] = useState(false);
  return (
    <div
      onMouseOver={() => {
        setActive(true);
      }}
      onMouseLeave={() => {
        setActive(false);
      }}
      className={"language-picker " + (active ? "active" : "")}
    >
      <p className={"language-picker-item"}>
        <img
          className={"language-picker-item-flag"}
          alt={language.flag}
          src={"/assets/images/flags/" + language.flag}
        />
        <span className={"language-picker-item-name"}>{language.name}</span>
        <span className={"language-picker-item-name short"}>
          {language.code}
        </span>
        <img
          className={"language-picker-item-down"}
          alt={"down"}
          src={"/assets/images/down.svg"}
        />
      </p>
      <ul>
        {languages.map((item) => {
          return (
            <li
              className={"language-picker-item"}
              onClick={() => {
                setActive(false);
                store.dispatch(changeLanguage({ language: item.code }));
              }}
              key={item.code}
            >
              <img
                className={"language-picker-item-flag"}
                alt={item.flag}
                src={"/assets/images/flags/" + item.flag}
              />
              <span className={"language-picker-item-name short"}>
                {item.code}
              </span>
              <span className={"language-picker-item-name"}>{item.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default React.memo(LanguagePicker);
