import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import React, { useMemo } from "react";
import QRCode from "react-qr-code";
import { store } from "../../store";
import getQrSession from "../../services/get-qr-session";
import TimerTitle from "./timer-title";

function QrContent() {
  const { t } = useTranslation();
  const { qrCode, qrStatus, language, qrCodeRefreshTime } = useSelector(
    (store) => ({
      qrCode: store.app.qrCode,
      qrStatus: store.app.qrStatus,
      language: store.app.langauge,
      qrCodeRefreshTime: store.app.qrCodeRefreshTime,
    }),
    shallowEqual
  );
  const qrStatusText = useMemo(() => {
    switch (qrStatus) {
      case "loading":
      case "idle":
        return t("qr.stateLoading");
      case "invalid":
      case "not-found":
        return t("invalidRequestDescription");
      case "waiting":
        return t("qr.stateWaiting");
      case "connected":
        return t("qr.stateConnected");
      case "expired":
        return t("qr.stateExpired");
      default:
        return qrStatus;
    }
    //NOTE: language gereksiz değil. Dil değişiminin tetiklenmesi lazım
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrStatus, language, t]);
  const qrColorClass = useMemo(() => {
    switch (qrStatus) {
      case "loading":
      case "idle":
      case "waiting":
        return "yellow";
      case "invalid":
      case "expired":
      case "not-found":
        return "red";
      case "connected":
        return "green";
      default:
        return qrStatus;
    }
  }, [qrStatus]);

  const render = useMemo(() => {
    switch (qrStatus) {
      case "loading":
      case "idle":
        return (
          <div className={"loading-spin"}>
            <img alt={"spin"} src={"/assets/images/spin.svg"} />
          </div>
        );
      case "waiting":
        return (
          <QRCode
            size={32}
            bgColor={"#f4f4f4"}
            style={{ height: "auto", maxWidth: "100%", width: "232px" }}
            value={qrCode}
            viewBox={`0 0 32 32`}
          />
        );
      case "connected":
        return (
          <div>
            <img alt={"spin"} src={"/assets/images/succeed.svg"} />
          </div>
        );
      case "not-found":
        return <div>{t("invalidRequestTitle")}</div>;
      case "expired":
      case "invalid":
        return (
          <button
            onClick={() => {
              const session = store.getState().app.session;
              getQrSession(session);
            }}
          >
            <img alt={"spin"} src={"/assets/images/spin.svg"} />
          </button>
        );
      default:
        return null;
    }
  }, [qrCode, qrStatus, t]);
  return (
    <div className={"qr-content"}>
      <TimerTitle qrStatus={qrStatus} qrCodeRefreshTime={qrCodeRefreshTime} />
      <div className={"qr-inner"}>{render}</div>
      <p>
        <span className={"qr-status " + qrColorClass} />
        {qrStatusText}
      </p>
    </div>
  );
}
export default React.memo(QrContent);
