import React from "react";
import Header from "./header/header";
function MainLayout({ children, rtl }) {
  return (
    <div className={"main-layout " + (rtl ? "rtl" : "")}>
      <Header />
      <div className={"main-layout-inner"}>{children}</div>
    </div>
  );
}
export default React.memo(MainLayout);
