import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SocialShare from "./social-share";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard/src";

function MainInput() {
  const qrCode = useSelector((store) => store.app.qrCode);
  const inputText =
    window.location.host + window.location.pathname + window.location.search;
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <div className={"main-input-container"}>
      <label>{t("qr.qrUrl")}</label>
      <div className={"main-input " + (qrCode ? "enabled" : "")}>
        <input defaultValue={inputText} readOnly />
        <CopyToClipboard onCopy={onCopy} text={inputText}>
          <button>{copied ? t("buttonCopySuccess") : t("buttonCopy")}</button>
        </CopyToClipboard>
      </div>
      <SocialShare url={inputText} />
    </div>
  );
}
export default React.memo(MainInput);
