import queryString from "query-string";
import UrlPattern from "url-pattern";

export function parseUrl() {
  const pattern = new UrlPattern("/wa(/:session)(/*)");
  const result = pattern.match(window.location.pathname);
  let langCode =
    (navigator.language || navigator.userLanguage)?.split("-")[0] || "en";
  const language = queryString.parse(window.location.search).lang || langCode;
  return { session: result?.session || null, language };
}
export function stringifyQueryStringUrl(lang) {
  const parsedQuery = queryString.parse(window.location.search);
  parsedQuery.lang = lang;
  const parsedQueryString = queryString.stringify(parsedQuery);
  if (window.history.pushState) {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      parsedQueryString;
    window.history.replaceState({ path: newurl }, "", newurl);
  } else {
    window.location.search = parsedQueryString;
  }
}
