import React, { useEffect } from "react";
import MainInput from "./main-input";
import getQrSession from "../../services/get-qr-session";
import { shallowEqual, useSelector } from "react-redux";
import QrContent from "./qr-content";
import MainContent from "./main-content";

function MainSection() {
  const { session, init } = useSelector(
    (store) => ({
      session: store.app.session,
      init: store.app.init,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (session && init) {
      getQrSession(session);
    }
  }, [session, init]);
  return (
    <section className={"main-section"}>
      <div className={"main-section-left"}>
        <MainInput />
        <MainContent />
      </div>
      <div className={"main-section-right"}>
        <QrContent />
      </div>
    </section>
  );
}
export default React.memo(MainSection);
