import React, { useEffect } from "react";
import MainSection from "./components/main/main-section";
import VideoSection from "./components/video-section";
import FaqSection from "./components/faq-section";
import MainLayout from "./layout/main-layout";
import { initApp } from "./reducers/app-reducer";
import { store } from "./store";
import InitializePusher from "./initialize-pusher";
import { useSelector } from "react-redux";

function AppInner() {
  const language = useSelector((s) => s.app.language);
  useEffect(() => {
    store.dispatch(initApp());
  }, []);
  return (
    <MainLayout rtl={language.rtl}>
      <InitializePusher />
      <MainSection />
      <VideoSection />
      <FaqSection />
    </MainLayout>
  );
}
export default React.memo(AppInner);
