import React, { useEffect } from "react";
import Pusher from "pusher-js";
import { shallowEqual, useSelector } from "react-redux";
import { store } from "./store";
import { initQR, qrConnected, qrExpired } from "./reducers/app-reducer";

function InitializePusher() {
  const { session, qrReadyForPusher } = useSelector(
    (store) => ({
      session: store.app.session,
      qrReadyForPusher: store.app.qrReadyForPusher,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (session && qrReadyForPusher) {
      const pusher = new Pusher("c393b767118b52501798", {
        cluster: "eu",
        encrypted: true,
      });
      Pusher.logToConsole = false;
      const channel = pusher.subscribe(session);
      channel.bind("qr-refreshed", (data) => {
        console.log("qr-refreshed", data);
        store.dispatch(initQR({ qrCode: data.qrCode, time: data.time }));
      });

      channel.bind("connection", (data) => {
        console.log("connection", data);
        if (data.status === "disconnected") {
          store.dispatch(qrExpired());
        } else {
          store.dispatch(qrConnected());
        }
        console.log("connection", data);
      });
    }
  }, [qrReadyForPusher, session]);
  return <></>;
}
export default React.memo(InitializePusher);
