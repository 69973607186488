import { useTranslation } from "react-i18next";
import React from "react";

function MainContent() {
  const { t } = useTranslation();
  return (
    <div className={"main-content"}>
      <h3>{t("connectQr")}</h3>
      <p>{t("openWhatsapp")}</p>
      <p>
        {t("descriptionTap")} <strong>{t("descriptionTapMenu")}</strong>
        <img alt={"settings-1"} src={"/assets/images/settings-1.svg"} />
        {t("descriptionOr")} <strong>{t("descriptionSettings")}</strong>
        <img
          className={"main-content-setting-2-img"}
          alt={"settings-2"}
          src={"/assets/images/settings-2.svg"}
        />
        {t("descriptionAndSelect")}{" "}
        <strong>{t("descriptionLinkedDevice")}</strong>
      </p>
      <p>
        {t("descriptionTapOn")} <strong>{t("descriptionLinkDevice")}</strong>
      </p>
      <p>{t("descriptionExp")}</p>
    </div>
  );
}
export default React.memo(MainContent);
