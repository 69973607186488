import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import faqData from "../assets/faq.json";
function FaqSection() {
  const { t } = useTranslation();
  return (
    <section className={"faq-section"}>
      {faqData.map((item) => (
        <div key={item.question}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>{t(item.question)}</b>
            </AccordionSummary>
            <AccordionDetails>{t(item.answer)}</AccordionDetails>
          </Accordion>
        </div>
      ))}
    </section>
  );
}
export default React.memo(FaqSection);
