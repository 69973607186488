import { createSlice } from "@reduxjs/toolkit";
import { parseUrl, stringifyQueryStringUrl } from "../helpers/parse-url";
import i18n from "i18next";
import { languages } from "../assets/languages";
import QR_BACKEND_STATES from "../helpers/qr-backend-status";

const appReducer = createSlice({
  name: "app",
  initialState: {
    init: false,
    session: null,
    language: languages[0],
    qrStatus: "loading",
    qrCode: null,
    qrReadyForPusher: false,
    qrCodeRefreshTime: null,
  },
  reducers: {
    initApp: function (state, action) {
      state.init = true;
      const { session, language } = parseUrl();
      const languageObj =
        languages.find((l) => l.code === language.toUpperCase()) ||
        languages[0];
      i18n.changeLanguage(languageObj.code.toLowerCase()).then();
      state.session = session;
      state.qrStatus = !session ? "not-found" : state.qrStatus;
      state.language = languageObj;
    },
    changeLanguage: function (state, action) {
      const languageObj =
        languages.find(
          (l) => l.code === action.payload.language.toUpperCase()
        ) || languages[0];
      if (state.language.code !== languageObj.code) {
        i18n.changeLanguage(languageObj.code.toLowerCase()).then();
        stringifyQueryStringUrl(languageObj.code.toLowerCase());
        state.language = languageObj;
      }
    },
    sessionLoadingStart: function (state, action) {
      if (state.qrStatus === "not-found") return;
      state.qrStatus = "loading";
    },
    initSession: function (state, action) {
      if (!state.init) {
        throw new Error("Please call this action after initApp");
      } else {
        if (state.qrStatus === "not-found") return;
        const data = action.payload.session;
        state.qrStatus = QR_BACKEND_STATES[data.status];
        state.qrReadyForPusher = true;
      }
    },
    errorSession: function (state, action) {
      if (state.qrStatus === "not-found") return;
      state.qrStatus = "invalid";
      console.error("Api session cannot initialized", action.payload);
    },
    initQR: function (state, action) {
      if (state.qrStatus === "not-found") return;
      if (state.qrStatus === "waiting" || state.qrStatus === "loading") {
        state.qrCode = action.payload.qrCode;
        state.qrCodeRefreshTime = action.payload.time * 1000;
        state.qrStatus = "waiting";
      }
    },
    qrExpired: function (state, action) {
      if (state.qrStatus === "not-found") return;
      if (state.qrStatus === "waiting") {
        state.qrCode = null;
        state.qrCodeRefreshTime = null;
        state.qrStatus = "expired";
      }
    },
    qrConnected: function (state, action) {
      if (state.qrStatus === "not-found") return;
      state.qrStatus = "connected";
    },
  },
});
export const {
  initApp,
  changeLanguage,
  errorSession,
  sessionLoadingStart,
  initSession,
  initQR,
  qrExpired,
  qrConnected,
} = appReducer.actions;
export default appReducer.reducer;
