import React from "react";
import { useTranslation } from "react-i18next";

function Video() {
  const { t } = useTranslation();
  return (
    <iframe
      width="560"
      height="315"
      src={t("video")}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
}
function VideoSection() {
  const { t } = useTranslation();
  return (
    <section className={"video-section"}>
      <h2>{t("tutorial")}</h2>
      <h4>{t("needStart")}</h4>
      <Video />
    </section>
  );
}
export default React.memo(VideoSection);
