import axios from "axios";
import { store } from "../store";
import {
  errorSession,
  initSession,
  sessionLoadingStart,
} from "../reducers/app-reducer";

export default function getQrSession(session) {
  store.dispatch(sessionLoadingStart());
  return axios
    .get(`https://api.whenguard.com/api/wa/session/${session}`)
    .then((res) => res.data)
    .then((data) => {
      store.dispatch(initSession(data));
    })
    .catch((e) => {
      store.dispatch(errorSession(e));
    });
}
