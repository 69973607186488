export const languages = [
  { code: "EN", isoCode: "GB", name: "English", flag: "GB.webp", rtl: false },
  { code: "AR", isoCode: "SA", name: "Arabic", flag: "SA.webp", rtl: true },
  { code: "DE", isoCode: "DE", name: "German", flag: "DE.webp", rtl: false },
  { code: "IT", isoCode: "IT", name: "Italian", flag: "IT.webp", rtl: false },
  { code: "ES", isoCode: "ES", name: "Spanish", flag: "ES.webp", rtl: false },
  { code: "JA", isoCode: "JP", name: "Japanese", flag: "JP.webp", rtl: false },
  { code: "NL", isoCode: "NL", name: "Dutch", flag: "NL.webp", rtl: false },
  {
    code: "PT",
    isoCode: "PT",
    name: "Portuguese",
    flag: "PT.webp",
    rtl: false,
  },
  { code: "FR", isoCode: "FR", name: "French", flag: "FR.webp", rtl: false },
  { code: "RU", isoCode: "RU", name: "Russia", flag: "RU.webp", rtl: false },
  { code: "HE", isoCode: "IL", name: "Hebrew", flag: "IL.webp", rtl: true },
  {
    code: "ZH",
    isoCode: "CN",
    name: "Simplified Chinese",
    flag: "CN.webp",
    rtl: false,
  },
  { code: "TR", isoCode: "TR", name: "Türkçe", flag: "TR.webp", rtl: false },
  { code: "RO", isoCode: "RO", name: "Romanian", flag: "RO.webp", rtl: false },
  { code: "HU", isoCode: "HU", name: "Hungarian", flag: "HU.webp", rtl: false },
  { code: "UK", isoCode: "UA", name: "Ukrainian", flag: "UA.webp", rtl: false },
  { code: "HI", isoCode: "IN", name: "Hindu", flag: "IN.webp", rtl: false },
  { code: "KO", isoCode: "KR", name: "Korean", flag: "KR.webp", rtl: false },
  { code: "SV", isoCode: "SE", name: "Swedish", flag: "SE.webp", rtl: false },
  {
    code: "VI",
    isoCode: "VI",
    name: "Vietnamese",
    flag: "VI.webp",
    rtl: false,
  },
  {
    code: "ID",
    isoCode: "ID",
    name: "Indonesian",
    flag: "ID.webp",
    rtl: false,
  },
];
