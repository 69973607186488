import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { store } from "../../store";
import { qrExpired } from "../../reducers/app-reducer";
const countDown = 60;

function TimerTitle({ qrCodeRefreshTime, qrStatus }) {
  const { t } = useTranslation();
  //cp: https://stackoverflow.com/a/57137212
  const [timeLeft, setTimeLeft] = useState(countDown);
  useEffect(() => {
    if (qrCodeRefreshTime) {
      setTimeLeft(countDown);
    }
  }, [qrCodeRefreshTime]);

  useEffect(() => {
    if (timeLeft < 1 && qrStatus === "waiting") {
      store.dispatch(qrExpired());
      setTimeLeft(countDown);
    }
  }, [qrStatus, timeLeft]);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft || qrStatus !== "waiting") return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [qrStatus, timeLeft]);
  if (qrStatus !== "waiting") return null;
  return (
    <h3>{t("qr.willRefresh", { count: timeLeft < 0 ? "" : timeLeft })}</h3>
  );
}
export default React.memo(TimerTitle);
