import {
  EmailIcon,
  EmailShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TelegramIcon,
  TelegramShareButton,
  VKIcon,
  VKShareButton,
  WhatsappShareButton,
} from "react-share";
import React from "react";

function SocialShare({ url }) {
  return (
    <div className={"social-share"}>
      <div>
        <WhatsappShareButton url={url}>
          <img
            alt={"whatsapp"}
            className={"social-share-whatsapp"}
            src={"/assets/images/whatsapp.svg"}
          />
        </WhatsappShareButton>
      </div>
      <div>
        <EmailShareButton url={url}>
          <EmailIcon size={40} borderRadius={16} round={false} />
        </EmailShareButton>
      </div>
      <div>
        <VKShareButton url={url}>
          <VKIcon size={40} borderRadius={16} round={false} />
        </VKShareButton>
      </div>
      <div>
        <TelegramShareButton url={url}>
          <TelegramIcon size={40} borderRadius={16} round={false} />
        </TelegramShareButton>
      </div>
      <div>
        <FacebookMessengerShareButton url={url}>
          <FacebookMessengerIcon size={40} round={true} />
        </FacebookMessengerShareButton>
      </div>
    </div>
  );
}
export default React.memo(SocialShare);
